import VueRouter from 'vue-router'

const router = new VueRouter({
    mode: 'hash',
    routes:[
        {
            path:'/',
            name:'home',
            component:()=>import('@/views/Home.vue')
        },
        {
            path:'/product',
            component:()=>import('@/views/Product.vue')
        },
        {
            path:'/buy',
            component:()=>import('@/views/Buy.vue')
        },
        {
            path:'/car',
            component:()=>import('@/views/Car.vue')
        },
        {
            path:'/my',
            component:()=>import('@/views/My.vue')
        },
		{
			path:'/bindphone',
			component:()=>import('@/views/BindPhone.vue')
		},
		{
			path:'/balance',
			component:()=>import('@/views/Balance.vue')
		},
		{
			path:'/orderlist',
			component:()=>import('@/views/Orderlist.vue')
		},
		{
			path:'/servicelist',
			component:()=>import('@/views/Servicelist.vue')
		}
    ]    
})

export default router