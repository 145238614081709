import { http } from '../request'

var api = {
    productlist(params){
        return http({
            url: '/product/list',
            method: 'get',
            params: params
        })
    },
    productdetail(params){
        return http({
            url:'/product/detail',
            method:'get',
            params: params
        })
    },
    producttype(){
        return http({
            url:'/product/types',
            method: 'get'
        })
    },
	//获取微信签名信息，wx.config使用
	getSignature(params){
		return http({
			url:'/wx/getSignature',
			method:'get',
			params: params
		})
	},
	//获取微信授权登录地址
	getOauth2Url(params){
		return http({
			url:'/wx/getOauth2Url',
			method:'get',
			params:params
		})
	},
	//根据openid获取微信用户信息
	getWeUser(params){
		return http({
			url:'/wx/getWeUser',
			method:'get',
			params: params
		})
	},
	//更新微信用户信息
	updateUser(params){
		return http({
			url:'/wx/updateUser',
			method:'post',
			data: params
		})
	},
	getBalance(params){
		return http({
			url:'/customer/getBalance',
			method:'get',
			params: params
		})
	},
	getBalancelist(params){
		return http({
			url:'/customer/balancelist',
			method:'get',
			params: params
		})
	},
	getOrderlist(params){
		return http({
			url:'/order/list',
			method:'get',
			params:params
		})
	},
	getServicelist(params){
		return http({
			url:'/order/services',
			method:'get',
			params:params
		})
	},
	sendSms(params){
		return http({
			url:'/wx/sendSms',
			method:'post',
			params:params
		})
	},
	getOrders(params){
		return http({
			url:'/order/count',
			method:'get',
			params: params
		})
	},
	addOrder(data){
		return http({
			url:'/order/submit',
			method:'post',
			data:data
		})
	},
	getShopInfo(params){
		return http({
			url:'/customer/shop',
			method:'get',
			params: params
		})
	},
	addOrders(data){
		return http({
			url:"/order/batchSubmit",
			method:"post",
			data:data
		})
	}
}

export default {
    install(Vue){
        Vue.prototype.$service = {
            ...api
        }
    },
    ...api
}