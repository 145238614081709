<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
import { wxLogin } from './wechat'

export default {
	name: 'App',
	mounted() {
		wxLogin()
	},
	methods: {
		
	}

}
</script>

<style>
.router-link-active {
	text-decoration: none;
	color: #000;
}
</style>
