import axios from 'axios'
import storage from 'store'
// import router from '../router'
import {Toast,Indicator} from 'mint-ui'


const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 0 // 请求超时时间
});

var errorHandler = error => {
    Indicator.close();
    // var res = error.response
    // var status = res.status;
    // if(status == 1000){
    //     Toast({
    //         message: '用户名或者密码不对,请重试',
    //         iconClass: 'icon icon-fail',
    //         position:'bottom'
    //       });
    // } else if(status == 403) {
    //     router.push({path:'/login'})
    //     return;
    // } 
    return Promise.reject(error)
}

instance.interceptors.request.use(config => {
    Indicator.open()
    //添加token和签名
    config.headers['access-token'] = storage.get('access-token')
    config.params = {
        ...config.params
    }
    return config;
},errorHandler)

instance.interceptors.response.use(response => {
    Indicator.close();
    return response.data
},errorHandler)

export {
    instance as http
}
