import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import MintUI from 'mint-ui'
import './assets/mint-ui-cust.css'
import './assets/iconfont/iconfont.css'
import Api from './api/index'
import {wxConfig} from './wechat'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false

Vue.use(MintUI)
Vue.use(VueRouter)
Vue.use(Api)
Vue.use(VueLazyload);

wxConfig()

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
