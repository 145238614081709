import Api from './api/index'
import store from 'store'
import { v4 as uuidv4 } from 'uuid'

// 微信配置信息
export function wxConfig() {
	var shopId = window.location.pathname.trim().substring(1)
	Api.getSignature({
		shopId: shopId
	}).then(res => {
		wx.config({
			...res,
			debug:false,
			jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
		});
		wx.error(res => {
			console.log(res)
		})
	})
}

//微信登录认证
export function wxLogin() {
	var env = process.env.NODE_ENV
	if (env == 'development') {
		store.set('wechat_user', {
			"id": "137c20e663a14ccb9e995e449388cce8",
			"createDate": "2023-07-05",
			"updateTime": "2023-07-06",
			"openid": "o_Kou5kkMVLwwAclQk_sFMZJj9Xs",
			"nickname": "贾永强",
			"sex": 0,
			"province": "",
			"city": "",
			"country": "",
			"headimgurl": "https://thirdwx.qlogo.cn/mmopen/vi_32/EaoibmbQyW77DIFsicUMf0v3BuTp43MDP13AmNnZwOvl0OPjUIHjd6oZ3dhU14l8FJwapfRUNDn7Q2dCdNfjFJuA/132",
			"unionid": null,
			"language": "",
			"privilege": null,
			"cardno": "NO.0000000",
			"phone": "15936285643",
			"state": "c8771bfa-7149-4ea0-8c9e-80f9a9def975"
		})
		store.set("shop_info", {
			"id":"f7407279c02249e7a1b4ab9738bcd19b",
			"addr":"河南郑州",
			"name":"养生1店"
		})
	}
	var shopId = window.location.pathname.trim().substring(1)
	if(shopId){
		store.set("shop_id", shopId)
		Api.getShopInfo({
			shopId: shopId
		}).then(res=>{
			store.set("shop_info", res)
		})
	}
	var wechatuser = store.get('wechat_user')
	if(wechatuser){
		Api.getWeUser({
			state: wechatuser.state
		}).then(res => {
			if(res){
				store.set('wechat_user', res)
				store.remove("we_state")
			}else{
				store.remove("we_state")
				store.remove("wechat_user")
				window.location.reload()
			}
		})
	}
	if (!wechatuser) {
		var state = store.get("we_state")
		if (state) {
			Api.getWeUser({
				state: state
			}).then(res => {
				store.set('wechat_user', res)
				store.remove("we_state")
			})
		} else {
			state = uuidv4()
			// alert(state)
			store.set("we_state", state)
			Api.getOauth2Url({
				state: state,
				shopId: shopId
			}).then(res => {
				window.location.href = res
			})
		}
	}
}

